import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <p>Welcome to Daniel Budusan website</p>
        <p>Website under construction, stay tuned</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/JZpjc7UFqlE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </header>
      <body>
      <h3> &copy; 2024 Daniel Budusan</h3>
      </body>
    </div>
  );
}

export default App;
